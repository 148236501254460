export default {
  login: '登录',
  next: '下一步',
  register: '注册',
  forget: '忘记密码',
  enter_phone: '请输入11位手机号',
  enter_pwd: '请输入密码',
  mobile_reg: '手机号注册',
  enter_code: '请输入验证码',
  enter_newpwd: '请输入新密码',
  done: '完成',
  enter_cor_phone: '请输入正确的手机号',
  followed: '已关注',
  follow: '关注',
  fans: '粉丝',
  visitor: '访客',
  wallet: '我的钱包',
  vip: '贵族中心',
  dynamic: '我的动态',
  collect: '我的收藏',
  intimacy: '亲密榜',
  streamer: '认证主播',
  revenue: '收益记录',
  guild: '主播公会',
  cooperation: '商务合作',
  logout: '退出登录',
  withdraw: '提现记录',
  diamond: '累计钻石',
  avl_diamond: '可提取钻石数',
  withdraw_account: '提现金额',
  enter_avl: '请输入要提取的钻石数',
  ava_account: '可到账金额',
  my_withdraw: '我的提现账户',
  manage_account: '管理账户',
  not_ver: '您还未进行实名认证',
  now_done: '立即完成',
  ali_account: '支付宝账户',
  ali_name: '支付宝姓名',
  withdraw_rul: '提现规则',
  rul1: '提现须绑定手机号和支付宝，且必须实名认证，因用户个人原因误填提现账户信息导致的后果，“OO LIVE官方”不承担任何责任',
  rul2: '申请提现一般会在24小时内发放至账户，如遇周末或节假日会顺延至工作日发放。',
  rul3: '提现比例可能会发生变动，以最新规则为准。',
  rul4: '最终解释权归“OO LIVE官方”所有。',
  now_withdraw: '立即提现',
  withdraw_cant_low: '提现金额不能小于',
  first_ver: '请先完成实名认证',
  apply_withdraw: '申请提现',
  collect_ali: '收款支付宝',
  transfer: '转账单号',
  mamage_withdraw: '管理提现账户',
  enter_name: '请输入支付宝姓名',
  enter_account: '请输入支付宝账号',
  phone_number: '手机号',
  enter_mobile: '请输入手机号',
  code: '验证码',
  now_edit: '立即修改账户',
  al_bind: '您的账号已绑定手机号，可直接进入下一步。',
  next_step: '下一步',
  text1: '真实姓名',
  text2: '请输入真实姓名',
  text3: '身份证号',
  text4: '请输入身份证号',
  text5: '身份验证',
  text6: '身份证正面',
  text7: '身份证背面',
  text8: '手持身份证',
  text9: '温馨提示',
  text10: '请确保姓名、身份证号清晰可见，这可以帮助客服人员快速通过你的申请',
  text11: '请选择图片文件',
  text12: '上传失败',
  text13: '请上传身份信息',
  text14: '申请成为主播',
  text15: '绑定手机',
  text16: '实名认证',
  text17: '填写个人资料',
  text18: '直播',
  text19: '动态',
  text20: '我的',
  text21: '请输入关键字',
  text22: '取消',
  text23: '主播',
  text24: '推荐',
  text25: '写动态',
  text26: '图片',
  text27: '视频',
  text28: '主页',
  text29: '短文',
  text30: '帖子',
  text31: '房间号',
  text32: '贡献榜',
  text33: '禁言列表',
  text34: '说点什么...',
  text35: '快来调侃一下主播吧',
  text36: '聊天',
  text37: '请选择您要做的操作',
  text38: '用户',
  text39: '禁言',
  text40: '请输入消息',
  text41: '提示',
  text42: '当前房间为付费房间，请前往APP观看',
  text43: '主播休息中',
  text44: '密码错误',
  text45: '发送',
  text46: '热门',
  text47: '足迹',
  text48: '贵族中心',
  text49: '游侠',
  text50: '骑士',
  text51: '公爵',
  text52: '国王',
  text53: '开通特效',
  text54: '全频道广播',
  text55: '贵族名片',
  text56: '尊贵身份象征',
  text57: '贵族名片',
  text58: '与主播零距离',
  text59: '亲密互动',
  text60: '贵族礼物',
  text61: '贵族专属礼物',
  text62: '贵族勋章',
  text63: '贵族专属勋章',
  text64: '私密动态',
  text65: '免费查看私密',
  text66: '动态',
  text67: '无限畅聊',
  text68: '与主播无限畅聊',
  text69: '防禁言',
  text70: '无法被房管禁言',
  text71: '赠送',
  text72: '金币',
  text73: '元',
  text74: '天',
  text75: '到期',
  text76: '进场欢迎',
  text77: '进场信息提示',
  text78: '解锁',
  text79: '的私密动态',
  text80: '观看此动态需要',
  text81: '私密房间',
  text82: '请输入房间密码',
  text83: '确定',
  text84: '条回复',
  text85: '礼物',
  text86: '充值',
  text87: '主播还未开播',
  text88: '系统消息',
  text89: '查看详情',
  text90: '已通过',
  text91: '待审核',
  text92: '未通过',
  text93: '收藏动态',
  text94: '礼物收益',
  text96: '联系电话',
  text97: '联系邮箱',
  text98: '联系QQ',
  text99: '普通房间',
  text100: '私密房间',
  text101: '付费房间',
  text102: '进入直播间',
  text103: '请输入消息',
  text104: '加入直播间',
  text105: '万',
  text106: '最新评论',
  text107: '发布评论',
  text108: '请输入评论内容',
  text109: '发布',
  text110: '记录生活，拥抱美好...',
  text111: '视频',
  text112: '封面',
  text113: '设置为付费观看动态',
  text114: '付费动态',
  text115: '尊敬的用户，您发布的内容须遵守相关法律法规和社区规则,请严格遵守相关规定，以免违规。',
  text116: '设置付费金额',
  text117: '请输入付费金额',
  text118: '请选择图片文件',
  text119: '请选择视频文件',
  text120: '上传失败',
  text121: '请输入您的动态内容',
  text122: '请上传视频封面',
  text123: '任务中心',
  text124: '大宁宁321',
  text125: '经验值',
  text126: '距离升级',
  text127: '每日登录',
  text128: '绑定手机号',
  text129: '发布动态',
  text130: '发布短视频',
  text131: '赠送礼物',
  text132: '消耗金币',
  text133: '解锁动态',
  text134: '个人资料',
  text135: '头像',
  text136: '昵称',
  text137: '个性签名',
  text138: '保存',
  text139: '严禁上传任何违反国家法律法规内容',
  text140: '富豪榜',
  text141: '明星榜',
  text142: '日榜',
  text143: '周榜',
  text144: '月榜',
  text145: '总榜',
  text146: '性别',
  text147: '请选择性别',
  text148: '年龄',
  text149: '请输入年龄',
  text150: '身高',
  text151: '请输入身高(cm)',
  text152: '体重',
  text153: '请输入体重(kg)',
  text154: '星座',
  text155: '请选择星座',
  text156: '职业',
  text157: '请输入职业',
  text158: '城市',
  text159: '请输入城市',
  text160: '个性签名',
  text161: '请编辑个性签名',
  text162: '提交申请',
  text163: '女',
  text164: '男',
  text165: '白羊座',
  text166: '金牛座',
  text167: '双子座',
  text168: '巨蟹座',
  text169: '狮子座',
  text170: '处女座',
  text171: '天秤座',
  text172: '天蝎座',
  text173: '射手座',
  text174: '摩羯座',
  text175: '水瓶座',
  text176: '双鱼座',
  text177: '人观看',
  text178: '加载中...',
  text179: '亲密总榜',
  text180: '亲密周榜',
  text181: '获取验证码',
  text182: '发送成功',
  text183: '热门榜单',
  text184: '付费精选',
  text185: '用户榜单',
  text186: '任务中心',
  text187: '热度',
  text188: '我的金币',
  text189: '请选择充值金额',
  text190: '注：通过充值获得的金币不可提现；充值成功后，到账可能会有一定的延迟，请您耐心等待',
  text191: '立即充值',
  text192: '微信支付',
  text193: '支付宝支付',
  text194: '请求超时，请稍后再试',
  text195: '最新版本',
  text196: '客服电话',
  text197: '客服QQ',
  text198: '客服邮箱',
  text199: '关于我们',
  text200: '管理员列表',
  text201: '没有更多了',
  text202: '取消房管',
  text203: '取消禁言'

}
