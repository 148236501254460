<template>
  <div class="live-message" :class="{ 'full-message': isFullScreen }">
    <scroll class="msg-scroll" ref="msgScroll">
      <h4 class="tips">
        {{ $store.state.appConfig.room_notice }}
      </h4>
      <ul class="msg-list">
        <li v-for="(msg, index) in msgs" :key="index">
          <img
            class="medal"
            v-if="msg.vip_level && msg.isVip"
            :src="medals[msg.vip_level - 1]"
            alt=""
          />
          <img :src="levelIcon[msg.user_level]" alt="" /><span
            @click.stop="showFloatUser(msg)"
            class="name"
            >{{ msg.nick_name }}: </span
          ><span>{{ msg.message }}</span>
        </li>
      </ul>
    </scroll>
    <form @submit.prevent="submitMsg" class="input" v-if="!isFullScreen">
      <input
        @blur="inputBlur"
        ref="input"
        v-model="message"
        :placeholder="$t('text35')"
        type="text"
      />
      <button type="submit" class="btn">{{$t('text45')}}</button>
    </form>
  </div>
</template>

<script>
import TIM from 'tim-js-sdk'
import { mapState, mapMutations } from 'vuex'
import levelIcon from '../../common/js/level-icon'
import Scroll from '../../components/scroll/scroll'
import { blurInput } from '../../common/js/mixins'
import { getItem } from '../../common/js/storage'

export default {
  name: 'live-message',
  mixins: [blurInput],
  components: {
    Scroll
  },
  props: {
    liveId: {
      type: [String, Number],
      default: ''
    },
    roomId: {
      type: String,
      default: ''
    },
    isAnchor: {
      type: Boolean,
      default: false
    },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      msgs: [],
      message: '',
      medals: [
        require('./youxia@2x.png'),
        require('./qishi@2x.png'),
        require('./gongjue@2x.png'),
        require('./king@2x.png')
      ],
      levelIcon,
      joinSuccess: false,
      webSocket: null
    }
  },
  computed: {
    ...mapState(['userInfo', 'appConfig', 'tim'])
  },
  mounted() {
    this.initIm()
    // this.initSocket()
  },
  beforeDestroy() {
    if (this.tim && this.joinSuccess && !this.isAnchor) {
      this.tim.quitGroup(this.roomId)
    }
    this.tim && this.tim.logout()
    this.tim.off(TIM.EVENT.MESSAGE_RECEIVED, this.receiveMsg)
    this.tim.off(TIM.EVENT.SDK_READY, this.imReady)
    clearTimeout(this.msgTimer)
    this.$off('showFloatUser')
    this.$off('liveFinished')
    this.$off('pushGift')
    this.$off('drawDanmu')
    this.$off('changHumanNum')
  },
  methods: {
    ...mapMutations(['changeIm']),
    // ---------------socket start--------------
    initSocket() {
      this.webSocket = new WebSocket('ws://live.nasilive.com:7272')
      this.webSocket.onopen = this.onopen
      this.webSocket.onmessage = this.onmessage
      this.webSocket.onerror = this.onerror
      this.webSocket.onclose = this.onclose
    },
    onopen() {
      console.log('-----onopen-----', this.webSocket.OPEN)
      // const msg = '进入直播间'
      //  判断 websocket 的状态
      if (this.webSocket.readyState === this.webSocket.OPEN) {
        // 已经打开,可以直接发送
        this.wsLogin()
        // this.sendWSMessage(msg, 'JOIN')
      } else if (this.webSocket.readyState === this.webSocket.CONNECTING) {
        // 正在开启状态中,则 1 秒后重新发送
        setTimeout(() => {
          this.onopen()
        }, 1000)
      }
    },
    onmessage(event) {
      if (event.data.indexOf('client_id') > -1) {
        const msg = this.$t('text102')
        this.sendWSMessage(msg, 'SocketActionJoinRoom')
        console.log(event.data)
      } else {
        const data = JSON.parse(event.data)
        switch (data.action) {
          case 'SocketActionJoinRoom': // 进入直播间
            this.showMsg(data.chat)
            break
          case 'SocketActionRoomMessage': // 直播间消息
            this.showMsg(data.chat)
            break
          case 'SocketActionRoomNotification': // 直播间通知消息
            console.log('收到礼物')
            this.$emit('pushGift', data.notify.gift)
            break
          default:
            break
        }
      }
    },
    onerror() {
      console.log('-----onerror-----')
    },
    onclose() {
      console.log('-----onclose-----')
    },
    wsLogin() {
      this.webSocket.send(
        JSON.stringify({
          type: 'login',
          uid: this.userInfo.id || getItem('tourist').userName.toString()
        })
      )
    },
    submitWSMsg(message) {
      if (message) {
        this.message = message
      }
      if (!this.userInfo.id) {
        this.$refs.input.blur()
        setTimeout(() => {
          this.$router.push('/login')
        }, 20)
        return
      }
      if (!this.message) {
        this.$toast.fail(this.$t('text103'))
        return
      }
      clearTimeout(this.msgTimer)
      this.msgTimer = setTimeout(() => {
        this.sendWSMessage(this.message, 'SocketActionRoomMessage')
      }, 200)
    },
    sendWSMessage(msg, action) {
      const tourist = getItem('tourist')
      const obj = {
        roomId: this.roomId,
        action: null,
        uid: this.userInfo.id || tourist.userName.toString(),
        liveId: this.liveId
      }
      obj.action = action
      obj.chat = {
        roomId: this.roomId,
        message: msg,
        textColor: '#FFFFFF',
        nameColor: '#8be8ff',
        isManager: 0,
        isGuardian: 0,
        chatType: 1,
        sender: {
          id: this.userInfo.id || 0,
          nick_name: this.userInfo.nick_name || tourist.userName,
          avatar: this.userInfo.avatar,
          user_level: this.userInfo.user_level
            ? this.userInfo.user_level.toString()
            : '1',
          vip_level: this.userInfo.vip_level,
          vip_date: this.userInfo.vip_date,
          is_anchor: this.userInfo.is_anchor
        }
      }
      obj.streamer = {
        roomId: this.roomId,
        type: null,
        user: null,
        anchor: null,
        gift: null,
        vip: null
      }
      obj.notify = {
        roomId: 0,
        type: null,
        isGuardian: 0,
        memberCount: 0,
        gift: null,
        fromUser: null,
        toUser: null,
        displayMessage: null,
        link_play_url: null,
        pkLive: null,
        pkInfo: null,
        goods: null
      }
      obj.system = {
        title: '',
        content: '',
        image_url: '',
        link: '',
        create_time: ''
      }
      this.webSocket.send(JSON.stringify(obj))
      if (action !== 'SocketActionJoinRoom') {
        this.showMsg(obj.chat)
      }
    },
    // ---------------socket end--------------
    submitMsg() {
      /*  if (message) {
        this.message = message
      } */
      if (!this.userInfo.id) {
        this.$refs.input.blur()
        setTimeout(() => {
          this.$router.push('/login')
        }, 20)
        return
      }
      if (!this.message) {
        this.$toast.fail(this.$t('text103'))
        return
      }
      clearTimeout(this.msgTimer)
      this.msgTimer = setTimeout(() => {
        this.sendMessage(this.message, 'RoomMessage')
      }, 200)
    },
    showFloatUser(user) {
      this.$emit('showFloatUser', user)
    },
    initIm() {
      if (!this.tim) {
        this.changeIm(
          TIM.create({
            SDKAppID: this.appConfig.im_sdkappid
          })
        )

        this.tim.setLogLevel(1)
      }

      this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.receiveMsg)

      this.tim.on(TIM.EVENT.SDK_READY, this.imReady)
      if (this.userInfo.id) {
        this.tim.login({
          userID: this.userInfo.id.toString(),
          userSig: this.userInfo.txim_sign
        })
      } else {
        const tourist = getItem('tourist')
        if (tourist) {
          this.tim.login({
            userID: tourist.userName.toString(),
            userSig: tourist.sign
          })
        }
      }
    },
    joinGroup() {
      if (!this.roomId) return
      return this.tim.joinGroup({ groupID: this.roomId }).then((e) => {
        this.joinSuccess = true
      })
    },
    receiveMsg(e) {
      e.data.forEach((item) => {
        if (!item.payload.data) return
        const data = JSON.parse(item.payload.data)
        if (data.Action === 'LiveFinished') this.$emit('liveFinished')
        if (data.Action === 'RoomMessage' && item.to === this.roomId) {
          if (data.Data.chat.sender.id === this.userInfo.id) {
            return
          }
          this.showMsg(data.Data.chat)
        }
        // console.log(data)
        // if (data.Action === 'RoomAttentAnchor') {
        //   this.showMSg(data)
        //   this.$emit('increaseAttend')
        // }
        if (data.Action === 'LiveGroupMemberJoinExit') this.getMemberNum()
        if (data.Action === 'GiftAnimation') this.$emit('pushGift', data)
      })
    },
    imReady() {
      this.joinGroup().then(() => {
        this.sendMessage(this.$t('text104'), 'RoomMessage')
      })
      this.getMemberNum()
    },
    sendMessage(message, action) {
      const tourist = getItem('tourist')

      const content = {
        Action: action,
        Data: {
          chat: {
            sender: {
              id: this.userInfo.id || 0,
              nick_name: this.userInfo.nick_name || tourist.userName,
              avatar: this.userInfo.avatar,
              user_level: this.userInfo.user_level
                ? this.userInfo.user_level.toString()
                : '1',
              vip_level: this.userInfo.vip_level,
              vip_date: this.userInfo.vip_date,
              is_anchor: this.userInfo.is_anchor
            },
            message: message
          }
        }
      }

      const msgBody = this.tim.createCustomMessage({
        to: this.roomId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          data: JSON.stringify(content)
        }
      })

      this.tim.sendMessage(msgBody).then(() => {
        this.message = ''
        this.showMsg(content.Data.chat)
      })
    },
    showMsg(chat) {
      this.msgs.push({
        message: chat.message,
        ...chat.sender,
        isVip: new Date(chat.sender.vip_date).getTime() > Date.now()
      })
      this.$emit('drawDanmu', chat.sender.nick_name + '：' + chat.message)
      this.$nextTick(() => {
        if (this.$refs.msgScroll) {
          this.$refs.msgScroll.scrollEnd()
        }
      })
    },
    getMemberNum() {
      if (!this.roomId) return
      this.tim
        .getGroupProfile({
          groupID: this.roomId
        })
        .then((res) => {
          this.$emit('changHumanNum', res.data.group.memberNum)
        })
    }
  }
}
</script>

<style scoped lang="stylus">
  @import "../../common/style/variable.styl"
.live-message
  height 100%
  box-sizing border-box
  padding-bottom 112px
  position relative
  background #F4F7F
  .msg-scroll
    height 100%
    overflow-y scroll
    .tips
      line-height 48px
      padding 25px 30px 32px 30px
      font-size 30px
      color #FF8A43
    .msg-list
      &>li
        margin-bottom 30px
        padding 0 30px
        line-height 40px
        font-size 30px
        color #333333
        .name
          color #B3B3B3
        &>img
          padding-right 14px
          display inline-block
          height 38px
          vertical-align top
          &.medal
            margin-top -5px
            height 48px
  .input
    position absolute
    left 0
    bottom 0
    box-sizing border-box
    padding 0 30px
    width 100%
    height 112px
    display flex
    align-items center
    background #fff
    &>input
      flex 1
      height 78px
      padding-left 30px
      border-radius 39px
      background #F5F5F5
      font-size 30px
    &>button
      margin-left 20px
      width 150px
      height 74px
      border none
      outline none
      border-radius 37px
      background $theme-color
      font-size 28px
      color #FFF
  &.full-message
    background none
    padding-bottom 0
    .msg-scroll
      .tips
        background rgba(0, 0, 0, .2)
        color #FFEF37
        margin-bottom 26px
        border-radius 10px
      .msg-list
        &>li
          color #FFF
          .name
            color #5FFCFF
</style>
