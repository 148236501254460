export default [
  '',
  require('../../assets/img/level/1@3x.png'),
  require('../../assets/img/level/2@3x.png'),
  require('../../assets/img/level/3@3x.png'),
  require('../../assets/img/level/4@3x.png'),
  require('../../assets/img/level/5@3x.png'),
  require('../../assets/img/level/6@3x.png'),
  require('../../assets/img/level/7@3x.png'),
  require('../../assets/img/level/8@3x.png'),
  require('../../assets/img/level/9@3x.png'),
  require('../../assets/img/level/10@3x.png'),
  require('../../assets/img/level/11@3x.png'),
  require('../../assets/img/level/12@3x.png'),
  require('../../assets/img/level/13@3x.png'),
  require('../../assets/img/level/14@3x.png'),
  require('../../assets/img/level/15@3x.png'),
  require('../../assets/img/level/16@3x.png'),
  require('../../assets/img/level/17@3x.png'),
  require('../../assets/img/level/18@3x.png'),
  require('../../assets/img/level/19@3x.png'),
  require('../../assets/img/level/20@3x.png')
]

export const anchorLevel = [
  '',
  require('../../assets/img/anchor-level/1@2x.png'),
  require('../../assets/img/anchor-level/2@2x.png'),
  require('../../assets/img/anchor-level/3@2x.png'),
  require('../../assets/img/anchor-level/4@2x.png'),
  require('../../assets/img/anchor-level/5@2x.png'),
  require('../../assets/img/anchor-level/6@2x.png'),
  require('../../assets/img/anchor-level/7@2x.png'),
  require('../../assets/img/anchor-level/8@2x.png'),
  require('../../assets/img/anchor-level/9@2x.png'),
  require('../../assets/img/anchor-level/10@2x.png'),
  require('../../assets/img/anchor-level/11@2x.png'),
  require('../../assets/img/anchor-level/12@2x.png'),
  require('../../assets/img/anchor-level/13@2x.png'),
  require('../../assets/img/anchor-level/14@2x.png'),
  require('../../assets/img/anchor-level/15@2x.png'),
  require('../../assets/img/anchor-level/16@2x.png'),
  require('../../assets/img/anchor-level/17@2x.png'),
  require('../../assets/img/anchor-level/18@2x.png'),
  require('../../assets/img/anchor-level/19@2x.png'),
  require('../../assets/img/anchor-level/20@2x.png')
]
