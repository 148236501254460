export default {
  login: 'login',
  next: 'Next step',
  register: 'register',
  forget: 'forget the password',
  enter_phone: 'Please enter 11 digit mobile number',
  enter_pwd: 'please enter password',
  mobile_reg: 'Mobile number registration',
  enter_code: 'enter verification code',
  enter_newpwd: 'Please enter a new password',
  done: 'Finish',
  enter_cor_phone: 'please enter a valid phone number',
  followed: 'Followed',
  follow: 'focus',
  fans: 'fans',
  visitor: 'visitors',
  wallet: 'My wallet',
  vip: 'Noble Center',
  dynamic: 'My dynamic',
  collect: 'My Favorites',
  intimacy: 'intimacy list',
  streamer: 'Authenticated streamer',
  revenue: 'Revenue record',
  guild: 'Anchor Guild',
  cooperation: 'business cooperation',
  logout: 'Log out',
  withdraw: 'withdrawal record',
  diamond: 'Cumulative Diamond',
  avl_diamond: 'The number of diamonds that can be extracted',
  withdraw_account: 'Withdrawal amount',
  enter_avl: 'Please enter the number of diamonds to be extracted',
  ava_account: 'The amount that can be received',
  my_withdraw: 'My withdrawal account',
  manage_account: 'manage account',
  not_ver: 'You have not verified your real name yet',
  now_done: 'Complete',
  ali_account: 'Alipay account',
  ali_name: 'Alipay name',
  withdraw_rul: 'withdrawal rules',
  rul1: "Withdrawal must be bound to mobile phone number and Alipay, and real-name authentication is required. 'OO LIVE Official' will not bear any responsibility for the consequences caused by the user's personal reasons for incorrectly filling in the withdrawal account information",
  rul2: 'Applications for cash withdrawals will generally be issued to the account within 24 hours, and will be postponed to working days in case of weekends or holidays. ',
  rul3: 'The withdrawal ratio may change, the latest rules shall prevail. ',
  rul4: 'The final interpretation right belongs to "OO LIVE Official". ',
  now_withdraw: 'Withdraw immediately',
  withdraw_cant_low: 'The withdrawal amount cannot be less than',
  first_ver: 'Please complete the real-name authentication first',
  apply_withdraw: 'Apply for withdrawal',
  collect_ali: 'Alipay',
  transfer: 'Transfer order number',
  mamage_withdraw: 'Manage withdrawal account',
  enter_name: 'enter Alipay name',
  enter_account: 'enter Alipay account number',
  phone_number: 'Mobile phone number',
  enter_mobile: 'enter your mobile number',
  code: 'Verification code',
  now_edit: 'Modify account now',
  al_bind: 'Your account has been bound with a mobile phone number, you can go directly to the next step. ',
  next_step: 'Next',
  text1: 'real name',
  text2: 'Please enter your real name',
  text3: 'ID card number',
  text4: 'Please enter your ID number',
  text5: 'Authentication',
  text6: 'ID card front',
  text7: 'The back of the ID card',
  text8: 'Handheld ID card',
  text9: 'Reminder',
  text10: 'Please ensure that your name and ID number are clear, this will help the customer service staff to quickly pass your application',
  text11: 'Please select a picture file',
  text12: 'Upload failed',
  text13: 'Please upload identity information',
  text14: 'Apply to be an anchor',
  text15: 'Bind mobile phone',
  text16: 'Real-name authentication',
  text17: 'Fill in personal information',
  text18: 'Live broadcast',
  text19: 'Dynamic',
  text20: 'My',
  text21: 'Please enter a keyword',
  text22: 'Cancel',
  text23: 'Anchor',
  text24: 'Recommended',
  text25: 'Dynamic',
  text26: 'Picture',
  text27: 'Video',
  text28: 'Homepage',
  text29: 'Essay',
  text30: 'Post',
  text31: 'room number',
  text32: 'Contribution list',
  text33: 'Forbidden list',
  text34: 'Say something...',
  text35: "Come on, let's make fun of the host",
  text36: 'Chat',
  text37: 'Please choose the operation you want to do',
  text38: 'User',
  text39: 'Forbidden',
  text40: 'Please enter a message',
  text41: 'Prompt',
  text42: 'The current room is a paid room, please go to the APP to watch',
  text43: 'The anchor is resting',
  text44: 'Password error',
  text45: 'Send',
  text46: 'popular',
  text47: 'footprint',
  text48: 'Noble Center',
  text49: 'Ranger',
  text50: 'Knight',
  text51: 'Duke',
  text52: 'King',
  text53: 'Enable special effects',
  text54: 'Full channel broadcast',
  text55: 'Noble business card',
  text56: 'Noble status symbol',
  text57: 'Noble business card',
  text58: 'Zero distance from the host',
  text59: 'Intimate interaction',
  text60: 'Noble gift',
  text61: 'Exclusive gift for nobles',
  text62: 'Noble Medal',
  text63: 'Exclusive medal for nobles',
  text64: 'Private dynamic',
  text65: 'View private for free',
  text66: 'Dynamic',
  text67: 'Unlimited chat',
  text68: 'Unlimited chat with the host',
  text69: 'Anti-forbidden speech',
  text70: 'Unable to be banned by the housing management',
  text71: 'gift',
  text72: 'Gold coins',
  text73: 'Yuan',
  text74: 'day',
  text75: 'Expires',
  text76: 'Welcome to the venue',
  text77: 'Entry information prompt',
  text78: 'Unlock',
  text79: 'Private dynamics',
  text80: 'You need to watch this dynamic',
  text81: 'Private room',
  text82: 'Please enter the room password',
  text83: 'Confirm',
  text84: 'reply',
  text85: 'gift',
  text86: 'Recharge',
  text87: 'The anchor has not started broadcasting',
  text88: 'System message',
  text89: 'View details',
  text90: 'Passed',
  text91: 'to be reviewed',
  text92: 'failed',
  text93: 'Favorite dynamic',
  text94: 'Gift income',
  text95: 'Dynamic income',
  text96: 'Contact number',
  text97: 'Contact email',
  text98: 'Contact QQ',
  text99: 'Ordinary room',
  text100: 'private room',
  text101: 'Paid room',
  text102: 'Enter the live broadcast room',
  text103: 'Please enter a message',
  text104: 'Join the live broadcast room',
  text105: '10,000',
  text106: 'Latest comments',
  text107: 'Publish a comment',
  text108: 'Please enter the comment content',
  text109: 'Release',
  text110: 'Record life, embrace beauty...',
  text111: 'Video',
  text112: 'Cover',
  text113: 'Set as paid',
  text114: 'Pay dynamic',
  text115: 'Dear user, the content you publish must comply with relevant laws and regulations and community rules, please strictly abide by the relevant regulations to avoid violations. ',
  text116: 'Set payment amount',
  text117: 'Please enter the payment amount',
  text118: 'Please select a picture file',
  text119: 'Please select a video file',
  text120: 'Upload failed',
  text121: 'Please enter your dynamic content',
  text122: 'Please upload the video cover',
  text123: 'Task Center',
  text124: 'Da Ning Ning 321',
  text125: 'Experience value',
  text126: 'Distance upgrade',
  text127: 'Daily login',
  text128: 'Bind mobile phone number',
  text129: 'Release news',
  text130: 'Publish a short video',
  text131: 'Give a gift',
  text132: 'Consume gold coins',
  text133: 'Unlock Dynamics',
  text134: 'Personal information',
  text135: 'Avatar',
  text136: 'nickname',
  text137: 'Personal signature',
  text138: 'Save',
  text139: 'It is strictly forbidden to upload any content that violates national laws and regulations',
  text140: 'Rich List',
  text141: 'Star list',
  text142: 'Daily List',
  text143: 'Weekly List',
  text144: 'Monthly list',
  text145: 'Total list',
  text146: 'Total list',
  text147: 'Please select a gender',
  text148: 'Age',
  text149: 'Please enter your age',
  text150: 'Height',
  text151: 'Please enter height (cm)',
  text152: 'Weight',
  text153: 'Please enter weight (kg)',
  text154: 'Constellation',
  text155: 'Please select a constellation',
  text156: 'Occupation',
  text157: 'Please enter occupation',
  text158: 'City',
  text159: 'Please enter the city',
  text160: 'Personal signature',
  text161: 'Please edit your signature',
  text162: 'Submit application',
  text163: 'Female',
  text164: 'male',
  text165: 'Aries',
  text166: 'Taurus',
  text167: 'Gemini',
  text168: 'Cancer',
  text169: 'Leo',
  text170: 'Virgo',
  text171: 'Libra',
  text172: 'Scorpio',
  text173: 'Sagittarius',
  text174: 'Capricorn',
  text175: 'Aquarius',
  text176: 'Pisces',
  text177: 'views',
  text178: 'loading...',
  text179: 'Comprehensive',
  text180: 'Intimacy Weekly',
  text181: 'Get code',
  text182: 'sent successfully',
  text183: 'Hot List',
  text184: 'Paid Featured',
  text185: 'User list',
  text186: 'Task Center',
  text187: 'hot',
  text188: 'My gold coins',
  text189: 'Please select the recharge amount',
  text190: 'Note: The gold coins obtained through recharge cannot be withdrawn; after the recharge is successful, there may be a certain delay in arriving, please wait patiently',
  text191: 'Recharge now',
  text192: 'WeChat payment',
  text193: 'Ali pay',
  text194: 'The request timed out, please try again later',
  text195: 'latest version',
  text196: 'Customer Service Phone',
  text197: 'Customer Service QQ',
  text198: 'Customer service email',
  text199: 'About us',
  text200: 'Administrator list',
  text201: 'No more',
  text202: 'Cancel housing management',
  text203: 'Unmute'
}
