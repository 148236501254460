import Vue from 'vue'
import VueI18n from 'vue-i18n'
import langZh from './zh'
import langEh from './en'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    zh: langZh,
    en: langEh
  }
})

Vue.prototype._i18n = i18n

export default i18n
